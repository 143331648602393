@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Paladins';
  src: url('./assets/fonts/paladins.ttf') format('truetype');
}

@font-face {
  font-family: 'PaladinsStraight';
  src: url('./assets/fonts/paladinsstraight.ttf') format('truetype');
}

@font-face {
  font-family: 'iceland';
  src: url('./assets/fonts/iceland.ttf') format('truetype');
}

body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
